<template>
    <div class="block-banner" :style="{ maxWidth: content.size.maxWidth + 'px' }">
        <img v-if="content.image" :src="content.image.url" alt=""/>
        <div v-else class="block-empty">
            <v-icon src="image"/>
        </div>
        <a class="block-banner__link" v-if="content.action.enable" :href="setLinkUrl(content.action.url)"></a>
    </div>
</template>

<script>
    import VIcon from "./VIcon";
    import {setLinkUrl} from "../config/helpers";

    export default {
        name: "BlockBanner",
        components: {
            VIcon
        },
        props: {
            content: {}
        },
        methods: {
            setLinkUrl,
        }
    };
</script>

<style scoped lang="scss">
    .block-banner {
        border: 1px solid rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }

        &__link {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 10;
        }

        .block-empty {
            position: relative;
            padding-top: 56%;

            .svg-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 100px;
            }
        }
    }
</style>
